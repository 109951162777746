import Layout from "../../components/layout"
import React from "react"
// import uczniowie from "@files/rekrutacja_uczniowie.pdf"
// import potwierdzenie from "@files/potwierdzenie_szkola.pdf"
// import harmonogram from "@static/documents/rekrutacja_SP/Harmonogram.odt"
// import potwierdzenie from "@static/documents/rekrutacja_SP/potwierdzenie woli_SP.doc"
// import wniosek from "@static/documents/rekrutacja_SP/wniosek_o_przyjecie_do_SP_kl I dziecka zam. poza obwodem szkoły 2022-2023.doc"
// import zal1 from "@static/documents/rekrutacja_SP/Załącznik nr 2 SP do wniosku.odt"
// import zal2 from "@static/documents/rekrutacja_SP/Załącznik nr 1 SP do wniosku.docx"
import SEO from "../../components/seo"
import * as css from "@components/content.module.css"

function Enrollment() {
  return (
    <Layout>
      <SEO title="Rekrutacja do szkoły - ZSP Nidek" />
      <h1>Rekrutacja do szkoły</h1>
      <p className={css.text}></p>
      <div className={css.links}>
        <p>
          {/* <a
            href="../../documents/rekrutacja_SP/Harmonogram.odt"
            download="Harmonogram.odt"
          >
            Harmonogram (16KB | odt)
          </a> */}
          <a
            href="../../documents/rekrutacja_SP/zarządzenie kl.1.pdf"
            download="zarządzenie kl.1.pdf"
          >
            Zarządzenie (729KB | pdf)
          </a>
        </p>
        <p>
          <a
            href="../../documents/rekrutacja_SP/Potwierdzenie woli_SP.doc"
            download="potwierdzenie_woli_przyjęcia_do_szkoły.doc"
          >
            Potwierdzenie woli przyjęcia do szkoły (12KB | doc)
          </a>
        </p>
        <p>
          <a
            href="../../documents/rekrutacja_SP/wniosek_o_przyjecie_do_SP_kl I dziecka zam. poza obwodem szkoły 2023-2024.doc"
            download="Wniosek.doc"
          >
            Wniosek o przyjęcie do SP kl I dziecka zam. poza obwodem szkoły
            2023-2024 (63KB | doc)
          </a>
        </p>
        <p>
          <a
            href="../../documents/rekrutacja_SP/Załacznik nr 2 SP do wniosku.docx"
            download="Załącznik_nr_2_SP_do_wniosku.docx"
          >
            Załącznik nr 2 SP do wniosku (16KB | docx)
          </a>
        </p>
        <p>
          <a
            href="../../documents/rekrutacja_SP/Załącznik nr 1 SP do wniosku.docx"
            download="Załącznik_nr_1_SP_do_wniosku.docx"
          >
            Załącznik nr 1 SP do wniosku (19KB | docx)
          </a>
        </p>
        <p>
          <a
            href="../../documents/rekrutacja_SP/zgłoszenie dziecka do kl.1 z obwodu 2023-24.docx"
            download="zgłoszenie dziecka do kl.1 z obwodu 2023-24.docx"
          >
            Zgłoszenie dziecka do kl.1 z obwodu 2023-24 (24KB | docx)
          </a>
        </p>
      </div>
    </Layout>
  )
}

export default Enrollment
